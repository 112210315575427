/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { questionCreateRequest, questionUpdateRequest } from "@alexgue/wrapby-types-web";
import JsonSchemaEntityForm from "../components/JsonSchemaEntityForm";
import { config } from "../config/config";
import { EntityDefinition } from "../types/EntityDefinition.type";
import CustomTable, { CustomTableProps } from "../components/CustomTable";
import { deleteQuestion, listQuestions, listTags } from "../utils/client";

const uiSchema = {
  type: {
    code: { "ui:widget": "hidden" },
    choices: {
      items: { tags: { items: { weight: { "ui:widget": "range" } } } },
    },
  },
  _id: { "ui:widget": "hidden" },
};

const entityDefinition: EntityDefinition = {
  title: { text: "Titulo", type: "text" },
  type: { text: "Tipo", type: "oneOf" },
  choicesArray: { text: "Respuestas / Etiquetas", type: "arrayOfArrays" },
};

const entity = "question";

export const FormQuestionContext = React.createContext({
  open: false,
  editMode: false,
  data: {},
  schema: {},
  setOpen: (open: boolean) => {},
  setEditMode: (editMode: boolean) => {},
  setData: (formData: any) => {},
  setSchema: (formSchema: any) => {},
});

const QuestionPage: React.FC = () => {
  const defaultTableData: CustomTableProps = {
    entityDefinition,
    items: [],
  };
  const [tableData, setTableData] = useState(defaultTableData);
  const [formOpen, setFormOpen] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [formData, setFormData] = React.useState({});
  const [formSchema, setFormSchema] = React.useState({});

  const toggleCreateModal = async () => {
    const schemaPopulated = JSON.parse(JSON.stringify(questionCreateRequest));
    const tags = await listTags();
    [0, 1].forEach((i) => {
      schemaPopulated.properties.type.oneOf[i].properties.choices.items.properties.tags.items.properties.text = {
        type: "string",
        enum: tags.map((question: any) => question.slug),
      };
    });
    setFormData({});
    setFormSchema(schemaPopulated);
    setFormEditMode(false);
    setFormOpen(true);
  };

  const toggleEditModal = async (data: any) => {
    console.log("data", data);
    const updateData = {
      ...data,
    };
    delete updateData.createdAt;
    delete updateData.updatedAt;
    delete updateData.choicesArray;
    const schemaPopulated = JSON.parse(JSON.stringify(questionUpdateRequest));
    const tags = await listTags();
    [0, 1].forEach((i) => {
      schemaPopulated.properties.type.oneOf[i].properties.choices.items.properties.tags.items.properties.text = {
        type: "string",
        enum: tags.map((question: any) => question.slug),
      };
    });
    console.log("schema", schemaPopulated);
    setFormData(updateData);
    setFormSchema(schemaPopulated);
    setFormEditMode(true);
    setFormOpen(true);
  };

  async function loadData() {
    const questions = await listQuestions();
    const props: CustomTableProps = {
      entityDefinition,
      items: questions.map((item) => {
        return {
          data: {
            ...item,
            choicesArray: item.type.choices,
          },
          // data: {
          //   _id: item._id,
          //   title: item.title,
          //   type: item.type.code,
          //   choices: item.type.choices.map((choice) => {
          //     return { title: choice.text, array: choice.tags.map((tag) => tag.text) };
          //   }),
          // },
          functions: { edit: toggleEditModal, delete: deleteQuestion },
        };
      }),
      update: loadData,
    };
    await setTableData(props);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <FormQuestionContext.Provider
      value={{
        open: formOpen,
        editMode: formEditMode,
        data: formData,
        schema: formSchema,
        setData: setFormData,
        setEditMode: setFormEditMode,
        setOpen: setFormOpen,
        setSchema: setFormSchema,
      }}
    >
      <JsonSchemaEntityForm
        uiSchema={uiSchema}
        context={FormQuestionContext}
        entity={entity}
        onCreate={() => loadData()}
      />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Preguntas</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={toggleCreateModal}
            className="relative px-3 py-2 text-sm focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Crear pregunta
          </button>
          <CustomTable tableData={tableData} />
        </div>
      </main>
    </FormQuestionContext.Provider>
  );
};

export default QuestionPage;
