/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, createContext } from "react";
import { Gift, giftCreateRequest, giftUpdateRequest } from "@alexgue/wrapby-types-web";
import CustomTable, { CustomTableProps } from "../components/CustomTable";
import { EntityDefinition } from "../types/EntityDefinition.type";
import { deleteGift, listGifts, listTags } from "../utils/client";
import JsonSchemaEntityForm from "../components/JsonSchemaEntityForm";

const uiSchema = {
  scoring: { items: { weight: { "ui:widget": "range" } } },
  _id: { "ui:widget": "hidden" },
};
const entity = "gift";

const entityDefinition: EntityDefinition = {
  title: { text: "Titulo", type: "text" },
  description: { text: "Descripcion", type: "text" },
  price: { text: "Precio", type: "text" },
  scoring: { text: "Etiquetas", type: "tags" },
  url: { text: "Url", type: "url" },
  images: { text: "Imagenes", type: "images" },
};

const defaultFormContext = {
  open: false,
  editMode: false,
  data: {},
  schema: {},
  setOpen: (open: boolean) => {},
  setEditMode: (editMode: boolean) => {},
  setData: (formData: Gift) => {},
  setSchema: (formSchema: typeof giftCreateRequest | typeof giftUpdateRequest) => {},
};

export const FormGiftContext = createContext(defaultFormContext);

const GiftPage: React.FC = () => {
  const defaultTableData: CustomTableProps = {
    entityDefinition,
    items: [],
  };

  const [tableData, setTableData] = useState(defaultTableData);
  const [formOpen, setFormOpen] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState({});

  const toggleCreateModal = async () => {
    const schemaPopulated = JSON.parse(JSON.stringify(giftCreateRequest));
    const tags = await listTags();
    schemaPopulated.properties.scoring.items.properties.text = {
      type: "string",
      enum: tags.map((tag) => tag.slug),
    };

    setFormData({});
    setFormSchema(schemaPopulated);
    setFormEditMode(false);
    setFormOpen(true);
  };

  const toggleEditModal = async (data: any) => {
    const updateData = {
      ...data,
    };
    delete updateData.createdAt;
    delete updateData.updatedAt;
    console.log("updateData", updateData);
    const schemaPopulated = JSON.parse(JSON.stringify(giftUpdateRequest));
    const tags = await listTags();
    schemaPopulated.properties.scoring.items.properties.text = {
      type: "string",
      enum: tags.map((tag) => tag.slug),
    };
    setFormData(updateData);
    setFormSchema(schemaPopulated);
    setFormEditMode(true);
    setFormOpen(true);
  };

  async function loadData() {
    const gifts = await listGifts();
    const props: CustomTableProps = {
      entityDefinition,
      items: gifts.map((gift: any) => {
        return {
          data: gift,
          functions: {
            edit: toggleEditModal,
            delete: deleteGift,
          },
        };
      }),
      update: loadData,
    };
    await setTableData(props);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <FormGiftContext.Provider
      value={{
        open: formOpen,
        editMode: formEditMode,
        data: formData,
        schema: formSchema,
        setData: setFormData,
        setEditMode: setFormEditMode,
        setOpen: setFormOpen,
        setSchema: setFormSchema,
      }}
    >
      <JsonSchemaEntityForm uiSchema={uiSchema} context={FormGiftContext} entity={entity} onCreate={() => loadData()} />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Regalos</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={toggleCreateModal}
            className="relative px-3 py-2 text-sm focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Crear regalo
          </button>

          <CustomTable tableData={tableData} />
        </div>
      </main>
    </FormGiftContext.Provider>
  );
};

export default GiftPage;
