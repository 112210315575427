export class Config {
  readonly port: number;

  readonly environment: string;

  readonly backendUrl: string;

  constructor() {
    this.port = parseInt(process.env.NODE_PORT || "8080", 10);
    this.environment = process.env.NODE_ENV;
    this.backendUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:8080";
  }
}

export const config = new Config();
