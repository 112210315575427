/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import { Form, HistoryEntry, Session, sessionCreateRequest, sessionUpdateRequest } from "@alexgue/wrapby-types-web";
import JsonSchemaEntityForm from "../components/JsonSchemaEntityForm";
import { EntityDefinition } from "../types/EntityDefinition.type";
import { deleteSession, getFormById, listForms, listSessions, listTags } from "../utils/client";
import CustomTable, { CustomTableProps } from "../components/CustomTable";

const uiSchema = { _id: { "ui:widget": "hidden" } };
const entity = "session";

const entityDefinition: EntityDefinition = {
  formTitle: { text: "Formulario", type: "text" },
  responses: { text: "Respuestas", type: "text" },
  createdAt: { text: "Fecha", type: "date-time" },
  clickPagesRejoined: { text: "Clicks/Paginas/Rejoined", type: "text" },
  completed: { text: "Completada", type: "text" },
  campaignId: { text: "Campaña", type: "text" },
  isBot: { text: "Bot", type: "text" },
  tempUserId: { text: "User", type: "text" },
};

const defaultFormContext = {
  open: false,
  editMode: false,
  data: {},
  schema: {},
  setOpen: (open: boolean) => {},
  setEditMode: (editMode: boolean) => {},
  setData: (formData: Session) => {},
  setSchema: (formSchema: typeof sessionCreateRequest | typeof sessionUpdateRequest) => {},
};

export const FormSessionContext = createContext(defaultFormContext);

type TableRowData = Session & {
  formPopulated: Form;
  responsesCount: number;
  totalCount: number;
};

const SessionPage: React.FC = () => {
  const defaultTableData: CustomTableProps = {
    entityDefinition,
    items: [],
  };

  const [tableData, setTableData] = useState(defaultTableData);
  const [formOpen, setFormOpen] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState({});

  const toggleCreateModal = async () => {
    const schemaPopulated = JSON.parse(JSON.stringify(sessionCreateRequest));
    const forms = await listForms();
    schemaPopulated.properties.formId = {
      type: "string",
      enum: forms.map((form) => form._id),
      enumNames: forms.map((form) => form.title),
    };
    setFormData({});
    setFormSchema(schemaPopulated);
    setFormEditMode(false);
    setFormOpen(true);
  };

  const toggleEditModal = async (data: any) => {
    const updateData = {
      ...data,
    };
    delete updateData.createdAt;
    delete updateData.updatedAt;
    const schemaPopulated = JSON.parse(JSON.stringify(sessionUpdateRequest));
    const forms = await listForms();
    schemaPopulated.properties.formId = {
      type: "string",
      enum: forms.map((form) => form._id),
      enumNames: forms.map((form) => form.title),
    };
    setFormData(updateData);
    setFormSchema(schemaPopulated);
    setFormEditMode(true);
    setFormOpen(true);
  };

  async function loadData() {
    const sessions = await listSessions();
    const formList = await listForms();
    const populatedData = await Promise.allSettled(
      sessions.map(async (session) => {
        const form = formList.find((x) => x._id === session.formId);
        const responsesCount = session.history.filter(
          (history) => history.type === "QUESTION_ANSWERED" || history.type === "MULTIPLE_QUESTION_ANSWERED",
        ).length;
        const totalCount = form?.possibleQuestions.length;
        const clickPagesRejoined = `${session.history.filter((history) => history.type === "GIFT_CLICK").length}/${
          session.history.filter((history) => history.type === "REJOINED_SESSION").length
        }/${session.history.filter((history) => history.type === "NEXT_PAGE_GIFTS").length}`;
        return {
          data: {
            ...session,
            formTitle: form?.title,
            responses: `${responsesCount} / ${totalCount}`,
            clickPagesRejoined,
            completed: !!session.results?.length,
          },
          functions: {
            edit: toggleEditModal,
            delete: deleteSession,
          },
        };
      }),
    ).catch((error) => {
      console.error(error);
      return [];
    });
    const props: CustomTableProps = {
      entityDefinition,
      items: populatedData
        .filter((data) => data.status === "fulfilled")
        .map((data: any) => data.value)
        .sort((a: any, b: any) => {
          if (a.data.createdAt > b.data.createdAt) {
            return -1;
          }
          if (a.data.createdAt < b.data.createdAt) {
            return 1;
          }
          return 0;
        }),

      update: loadData,
    };

    setTableData(props);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <FormSessionContext.Provider
      value={{
        open: formOpen,
        editMode: formEditMode,
        data: formData,
        schema: formSchema,
        setData: setFormData,
        setEditMode: setFormEditMode,
        setOpen: setFormOpen,
        setSchema: setFormSchema,
      }}
    >
      <JsonSchemaEntityForm
        uiSchema={uiSchema}
        context={FormSessionContext}
        entity={entity}
        onCreate={() => loadData()}
      />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Sesiones</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={toggleCreateModal}
            className="relative px-3 py-2 text-sm focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Crear sesión
          </button>
          <CustomTable tableData={tableData} />
        </div>
      </main>
    </FormSessionContext.Provider>
  );
};

export default SessionPage;
