/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import { Form, formCreateRequest, formUpdateRequest, Question } from "@alexgue/wrapby-types-web";
import JsonSchemaEntityForm from "../components/JsonSchemaEntityForm";
import { EntityDefinition } from "../types/EntityDefinition.type";
import CustomTable, { CustomTableProps } from "../components/CustomTable";
import { deleteForm, getQuestionById, listForms, listQuestions, listTags } from "../utils/client";

const entity = "form";
const uiSchema = {
  type: { code: { "ui:widget": "hidden" } },
  _id: { "ui:widget": "hidden" },
};

const entityDefinition: EntityDefinition = {
  title: { text: "Titulo", type: "text" },
  slug: { text: "Slug", type: "text" },
  questionsPopulated: { text: "Preguntas", type: "questionArray" },
};

const defaultFormContext = {
  open: false,
  editMode: false,
  data: {},
  schema: {},
  setOpen: (open: boolean) => {},
  setEditMode: (editMode: boolean) => {},
  setData: (formData: any) => {},
  setSchema: (formSchema: any) => {},
};

export const FormFormContext = createContext(defaultFormContext);

const FormPage: React.FC = () => {
  const defaultTableData: CustomTableProps = {
    entityDefinition,
    items: [],
  };
  const [tableData, setTableData] = useState(defaultTableData);
  const [formOpen, setFormOpen] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState({});

  const toggleCreateModal = async () => {
    const schemaPopulated = JSON.parse(JSON.stringify(formCreateRequest));
    const [questions, tags] = await Promise.all([listQuestions(), listTags()]);
    schemaPopulated.properties.possibleQuestions.items.properties.questionId = {
      type: "string",
      enum: questions.map((question: any) => question._id),
      enumNames: questions.map((question: any) => question.title),
    };
    schemaPopulated.properties.possibleQuestions.items.properties.conditions.items.properties.tag = {
      type: "string",
      enum: tags.map((tag: any) => tag.slug),
      enumNames: tags.map((tag: any) => tag.title),
    };

    console.log("createSchema", schemaPopulated);

    setFormSchema(schemaPopulated);
    setFormData({});
    setFormEditMode(false);
    setFormOpen(true);
  };

  const toggleEditModal = async (data: any) => {
    console.log("data", data);
    const updateData = {
      ...data,
    };
    delete updateData.createdAt;
    delete updateData.updatedAt;
    delete updateData.questionsPopulated;
    const schemaPopulated = JSON.parse(JSON.stringify(formUpdateRequest));
    const [questions, tags] = await Promise.all([listQuestions(), listTags()]);
    schemaPopulated.properties.preScore.items.properties.text = {
      type: "string",
      enum: tags.map((tag: any) => tag.slug),
    };
    schemaPopulated.properties.possibleQuestions.items.properties.questionId = {
      type: "string",
      enum: questions.map((question: any) => question._id),
      enumNames: questions.map((question: any) => question.title),
    };
    schemaPopulated.properties.possibleQuestions.items.properties.conditions.items.properties.tag = {
      type: "string",
      enum: tags.map((tag: any) => tag.slug),
      enumNames: tags.map((tag: any) => tag.title),
    };
    console.log("updSchema", schemaPopulated);

    setFormSchema(schemaPopulated);
    setFormData(updateData);
    setFormEditMode(true);
    setFormOpen(true);
  };

  type FormPopulated = Form & { questionsPopulated: Question[] };
  async function populateQuestions(forms: Form[]): Promise<FormPopulated[]> {
    const formPopulated: FormPopulated[] = [];
    await Promise.all(
      forms.map(async (form) => {
        formPopulated.push({
          ...form,
          questionsPopulated: (
            await Promise.allSettled(
              form.possibleQuestions.map(async (question) => await getQuestionById(question.questionId)),
            )
          )
            .filter((question) => question.status === "fulfilled")
            .map((question: any) => question.value),
        });
      }),
    );
    return formPopulated;
  }

  async function loadData() {
    const forms = await listForms();
    const dataPopulated = await populateQuestions(forms);
    const props: CustomTableProps = {
      entityDefinition,
      items: dataPopulated.map((item) => {
        return {
          data: item,
          functions: { edit: toggleEditModal, delete: deleteForm },
        };
      }),
      update: loadData,
    };
    await setTableData(props);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <FormFormContext.Provider
      value={{
        open: formOpen,
        editMode: formEditMode,
        data: formData,
        schema: formSchema,
        setData: setFormData,
        setEditMode: setFormEditMode,
        setOpen: setFormOpen,
        setSchema: setFormSchema,
      }}
    >
      <JsonSchemaEntityForm uiSchema={uiSchema} context={FormFormContext} entity={entity} onCreate={() => loadData()} />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Formularios</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={toggleCreateModal}
            className="relative px-3 py-2 text-sm focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Crear formulario
          </button>

          <CustomTable tableData={tableData} />
        </div>
      </main>
    </FormFormContext.Provider>
  );
};

export default FormPage;
