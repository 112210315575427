import React, { Fragment } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
// import NavBar from "./components/NavBar";
import QuestionPage from "./pages/QuestionPage";
import FormPage from "./pages/FormPage";
import TagPage from "./pages/TagPage";
import GiftPage from "./pages/GiftPage";
import SessionPage from "./pages/SessionPage";
import Login from "./Login";
import ClicksPage from "./pages/ClicksPage";

const user = {
  name: "Gift User",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

type NavigationItem = {
  name: string;
  href: string;
  current: boolean;
};
const navigation: NavigationItem[] = [
  { name: "Etiquetas", href: "tags", current: true },
  { name: "Regalos", href: "gifts", current: false },
  { name: "Sesiones", href: "sessions", current: false },
  { name: "Formularios", href: "forms", current: false },
  { name: "Clicks", href: "clicks", current: false },
  { name: "Preguntas", href: "questions", current: false },
];
const userNavigation = [
  // { name: "Tu perfil", href: "#" },
  // { name: "Settings", href: "#" },
  { name: "Logout", href: "/login" },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

function App() {
  const location = useLocation();
  const locationHref = location.pathname.split("/")[1];
  function Home() {
    return (
      <>
        <h2 className="text-xl text-center m-10">Bienvenido al panel de control de Wrapby</h2>
      </>
    );
  }

  function Forms() {
    return (
      <>
        <FormPage />
      </>
    );
  }
  function Questions() {
    return (
      <>
        <QuestionPage />
      </>
    );
  }
  function Tags() {
    return (
      <>
        <TagPage />
      </>
    );
  }
  function Gifts() {
    return (
      <>
        <GiftPage />
      </>
    );
  }

  function Sessions() {
    return (
      <>
        <SessionPage />
      </>
    );
  }

  function Clicks() {
    return (
      <>
        <ClicksPage />
      </>
    );
  }

  // Check token and redirect to login if not valid
  const token = localStorage.getItem("x-access-token");
  if (!token) {
    return <Login />;
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            <>
              {/* <NavBar /> */}

              <div className="min-h-full">
                <Disclosure as="nav" className="bg-gray-800">
                  {({ open }) => (
                    <>
                      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <img
                                className="h-8 w-8"
                                src="./caja-de-regalo.png
                                "
                                alt="Your Company"
                              />
                            </div>
                            <div className="hidden md:block">
                              <div className="ml-10 flex items-baseline space-x-4">
                                {navigation.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                      locationHref === item.href
                                        ? "bg-gray-900 text-white"
                                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                      "px-3 py-2 rounded-md text-sm font-medium",
                                    )}
                                    aria-current={locationHref === item.href ? "page" : undefined}
                                  >
                                    {item.name}
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                              <button
                                type="button"
                                className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                              >
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                              </button>

                              {/* Profile dropdown */}
                              <Menu as="div" className="relative ml-3">
                                <div>
                                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                    <span className="sr-only">Open user menu</span>
                                    <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {userNavigation.map((item) => (
                                      <Menu.Item key={item.name}>
                                        {({ active }) => (
                                          <a
                                            href={item.href}
                                            className={classNames(
                                              active ? "bg-gray-100" : "",
                                              "block px-4 py-2 text-sm text-gray-700",
                                            )}
                                          >
                                            {item.name}
                                          </a>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                          <div className="-mr-2 flex md:hidden">
                            {/* Mobile menu button */}
                            <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open main menu</span>
                              {open ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                              ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                              )}
                            </Disclosure.Button>
                          </div>
                        </div>
                      </div>

                      <Disclosure.Panel className="md:hidden">
                        <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                          {navigation.map((item) => (
                            <Link
                              style={{
                                color: "inherit",
                                textDecoration: "inherit",
                              }}
                              key={item.href}
                              to={`/${item.href}`}
                            >
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className={classNames(
                                  locationHref === item.href
                                    ? "bg-gray-900 text-white"
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "block px-3 py-2 rounded-md text-base font-medium",
                                )}
                                aria-current={locationHref === item.href ? "page" : undefined}
                              >
                                {item.name}
                              </Disclosure.Button>
                            </Link>
                          ))}
                        </div>
                        <div className="border-t border-gray-700 pt-4 pb-3">
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                            </div>
                            <div className="ml-3">
                              <div className="text-base font-medium leading-none text-white">{user.name}</div>
                              <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                            </div>
                            <button
                              type="button"
                              className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                            >
                              <span className="sr-only">View notifications</span>
                              <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            {userNavigation.map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>

              {/* Replace with your content */}

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="forms" element={<Forms />} />
                <Route path="questions" element={<Questions />} />
                <Route path="tags" element={<Tags />} />
                <Route path="gifts" element={<Gifts />} />
                <Route path="sessions" element={<Sessions />} />
                <Route path="clicks" element={<Clicks />} />
              </Routes>
              {/* /End replace */}
            </>
          }
        />
      </Routes>
    </>
  );
}
export default App;
