/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import { Form, HistoryEntry, Session, sessionCreateRequest, sessionUpdateRequest } from "@alexgue/wrapby-types-web";
import JsonSchemaEntityForm from "../components/JsonSchemaEntityForm";
import { EntityDefinition } from "../types/EntityDefinition.type";
import { deleteSession, getFormById, listForms, listGifts, listSessions, listTags } from "../utils/client";
import CustomTable, { CustomTableProps } from "../components/CustomTable";

const uiSchema = { _id: { "ui:widget": "hidden" } };
const entity = "session";

const entityDefinition: EntityDefinition = {
  formTitle: { text: "Formulario", type: "text" },
  giftTitle: { text: "Regalo", type: "text" },
  giftShop: { text: "Tienda", type: "text" },
  responses: { text: "Respuestas", type: "text" },
  createdAt: { text: "Fecha", type: "date-time" },
  campaignId: { text: "Campaña", type: "text" },
  tempUserId: { text: "User", type: "text" },
  images: { text: "Imagenes", type: "images" },
};

const defaultFormContext = {
  open: false,
  editMode: false,
  data: {},
  schema: {},
  setOpen: (open: boolean) => {},
  setEditMode: (editMode: boolean) => {},
  setData: (formData: Session) => {},
  setSchema: (formSchema: typeof sessionCreateRequest | typeof sessionUpdateRequest) => {},
};

export const FormSessionContext = createContext(defaultFormContext);

type TableRowData = Session & {
  formPopulated: Form;
  responsesCount: number;
  totalCount: number;
};

const ClicksPage: React.FC = () => {
  const defaultTableData: CustomTableProps = {
    entityDefinition,
    items: [],
  };

  const [tableData, setTableData] = useState(defaultTableData);
  const [formOpen, setFormOpen] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState({});

  const toggleCreateModal = async () => {
    const schemaPopulated = JSON.parse(JSON.stringify(sessionCreateRequest));
    const forms = await listForms();
    schemaPopulated.properties.formId = {
      type: "string",
      enum: forms.map((form) => form._id),
      enumNames: forms.map((form) => form.title),
    };
    setFormData({});
    setFormSchema(schemaPopulated);
    setFormEditMode(false);
    setFormOpen(true);
  };

  const toggleEditModal = async (data: any) => {
    const updateData = {
      ...data,
    };
    delete updateData.createdAt;
    delete updateData.updatedAt;
    const schemaPopulated = JSON.parse(JSON.stringify(sessionUpdateRequest));
    const forms = await listForms();
    schemaPopulated.properties.formId = {
      type: "string",
      enum: forms.map((form) => form._id),
      enumNames: forms.map((form) => form.title),
    };
    setFormData(updateData);
    setFormSchema(schemaPopulated);
    setFormEditMode(true);
    setFormOpen(true);
  };

  async function loadData() {
    const sessions = await listSessions();
    const formList = await listForms();
    const gifts = await listGifts();

    const clickData: { history: HistoryEntry; session: Session }[] = sessions.reduce(
      (acc, session) => {
        const click = session.history.find((history) => history.type === "GIFT_CLICK");
        if (click) {
          return [...acc, { history: click, session }];
        }
        return acc;
      },
      [] as { history: HistoryEntry; session: Session }[],
    );
    const populatedData = await Promise.allSettled(
      clickData.map(async (dat) => {
        if (dat.history.type === "GIFT_CLICK") {
          const { giftId } = dat.history;
          const form = formList.find((x) => x._id === dat.session.formId);
          const gift = gifts.find((x) => x._id === giftId);
          const responsesCount = dat.session.history.filter(
            (history) => history.type === "QUESTION_ANSWERED" || history.type === "MULTIPLE_QUESTION_ANSWERED",
          ).length;
          const totalCount = form?.possibleQuestions.length;
          return {
            data: {
              formTitle: form?.title,
              giftTitle: gift?.title,
              giftShop: gift?.url.split("url=")[1] ? gift.url.split("%2F")[2] : "Amazon",
              responses: `${responsesCount} / ${totalCount}`,
              createdAt: dat.history.createdAt,
              campaignId: dat.session.campaignId,
              tempUserId: dat.session.tempUserId,
              images: gift?.images,
            },
            functions: {},
          };
        }
        return {};
      }),
    ).catch((error) => {
      console.error(error);
      return [];
    });
    const props: CustomTableProps = {
      entityDefinition,
      items: populatedData
        .filter((data) => data.status === "fulfilled")
        .map((data: any) => data.value)
        .sort((a: any, b: any) => {
          if (a.data.createdAt > b.data.createdAt) {
            return -1;
          }
          if (a.data.createdAt < b.data.createdAt) {
            return 1;
          }
          return 0;
        }),

      update: loadData,
    };

    setTableData(props);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <FormSessionContext.Provider
      value={{
        open: formOpen,
        editMode: formEditMode,
        data: formData,
        schema: formSchema,
        setData: setFormData,
        setEditMode: setFormEditMode,
        setOpen: setFormOpen,
        setSchema: setFormSchema,
      }}
    >
      <JsonSchemaEntityForm
        uiSchema={uiSchema}
        context={FormSessionContext}
        entity={entity}
        onCreate={() => loadData()}
      />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Clicks</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <CustomTable tableData={tableData} />
        </div>
      </main>
    </FormSessionContext.Provider>
  );
};

export default ClicksPage;
