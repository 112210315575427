import React from "react";
import { EntityDefinition } from "../types/EntityDefinition.type";

export type CustomTableProps = {
  entityDefinition: EntityDefinition;
  items: {
    data: any;
    // types: any;
    functions: {
      edit: (data: any) => void;
      delete: (id: string) => void;
    };
  }[];
  update?: () => void;
};

type CustomTableInternalProps = {
  tableData: CustomTableProps;
};

const CustomTable = ({ tableData }: CustomTableInternalProps) => {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-200">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-200">
          <tr>
            {Object.values(tableData.entityDefinition).map((value) => (
              <th className="px-6 py-3">{value.text}</th>
            ))}
            <th className="px-6 py-3">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {tableData.items.map((item) => (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              {Object.keys(tableData.entityDefinition).map((field) => {
                const fieldData = item.data[field];
                switch (tableData.entityDefinition[field].type) {
                  case "number":
                    return <td className="px-6 py-4">{fieldData?.toString()}</td>;
                  case "date-time":
                    return <td className="px-6 py-4">{new Date(item.data[field]).toLocaleString()}</td>;
                  case "date":
                    return <td className="px-6 py-4">{new Date(item.data[field]).toLocaleDateString()}</td>;
                  case "text":
                    return <td className="px-6 py-4">{fieldData?.toString()}</td>;
                  case "oneOf":
                    return <td className="px-6 py-4">{fieldData.code}</td>;
                  case "url":
                    return (
                      <td className="px-6 py-4">
                        <a href={fieldData} target="_blank">
                          [URL]
                        </a>
                      </td>
                    );
                  case "tags":
                    return (
                      <td className="px-6 py-4 tex">
                        {fieldData?.map((tag: any) => (
                          <span className="bg-green-100 my-1 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 block">
                            {tag.text}
                            <span className="inline-flex items-center justify-center w-6 h-4 ml-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-md">
                              {tag.weight}
                            </span>
                          </span>
                        ))}
                      </td>
                    );
                  case "array":
                    return (
                      <td className="px-6 py-4 tex">
                        {fieldData?.map((question: any) => (
                          <span className="bg-green-100 my-1 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 block">
                            {question}
                          </span>
                        ))}
                      </td>
                    );
                  case "questionArray":
                    return (
                      <td className="px-6 py-4 tex">
                        {fieldData?.map((question: any) => (
                          <span className="bg-green-100 my-1 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 block">
                            {question.title}
                          </span>
                        ))}
                      </td>
                    );
                  case "arrayOfArrays":
                    console.log("arrayofarrays");
                    console.log(fieldData);
                    return (
                      <td className="px-6 py-4">
                        {fieldData?.map((param: any) => (
                          <div className="grid-cols-2 grid border-slate-600 border-2 rounded-lg m-1 p-1">
                            <div className="col-span-1 ">
                              <span className="bg-green-100 my-1 text-lime-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-lime-900 dark:text-lime-300 block">
                                {param.text}
                              </span>
                            </div>
                            <div className="col-span-1">
                              {param.tags?.map((element: any) => (
                                <span className="col-span-1 bg-green-100 my-1 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 block">
                                  {element.weight}
                                </span>
                              ))}
                            </div>
                          </div>
                        ))}
                      </td>
                    );
                  // case "responses":
                  //   return (
                  //     <td className="px-6 py-4 tex">
                  //       {fieldData?.map((val: any) => (
                  //         <span className="bg-green-100 my-1 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 block">
                  //           {val.title}
                  //         </span>
                  //       ))}
                  //     </td>
                  //   );
                  case "images":
                    return (
                      <td className="px-6 py-4">
                        {fieldData?.map((image: any) => (
                          <a href={image} target="_blank">
                            <img width="128" className="rounded my-2" src={image} />
                          </a>
                        ))}
                      </td>
                    );
                  default:
                    return <td className="px-6 py-4">(UNKNOWN TYPE) {fieldData}</td>;
                }
                return <></>;
              })}
              <td className="px-6 py-4">
                <button
                  type="button"
                  onClick={() => {
                    item.functions.edit(item.data);
                  }}
                  className="relative px-3 py-2 text-sm focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  Editar
                </button>
                <button
                  type="button"
                  onClick={async () => {
                    await item.functions.delete(item.data._id);
                    if (tableData.update) {
                      tableData.update();
                    }
                  }}
                  className="relative px-3 py-2 text-sm focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
