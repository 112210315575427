import { FormProps } from "@rjsf/core";
import * as React from "react";

function MyArrayFieldTemplate(props: any) {
  return (
    <>
      {/* <div className="border-2 border-slate-500">{props.items.map((element: any) => element.children)}</div> */}

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
        <table className="w-full text-sm text-left text-gray-500/50 dark:text-gray-200">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-green-700 dark:text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-1">
                <span className="inline-block align-middle text-sm">{props.title}</span>
                {props.canAdd && (
                  <button
                    onClick={props.onAddClick}
                    className="float-right bg-slate-800 hover:bg-slate-700 bg-grey-light hover:bg-grey text-grey-darkest font-bold py-1 px-1 m-0 rounded inline-block items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-3 h-3"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                  </button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {props.items.map((element: any) => {
              return (
                <tr className="bg-white border-b dark:bg-green-600/50">
                  <td scope="row" className="px-6 py-4">
                    {element.children}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* {props.canAdd && (
        <button
          type="button"
          onClick={props.onAddClick}
          className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Añadir {props.title}
        </button>
      )} */}
    </>
  );
}

function MyErrorListTemplate() {
  return <div />;
  // const { errors } = props;
  // return (
  //   <ul>
  //     {errors.map((error: any) => (
  //       <li key={error.stack}>{error.stack}</li>
  //     ))}
  //   </ul>
  // );
}

function CustomFieldTemplate(props: any) {
  const { id, classNames, help, description, errors, children } = props;
  return (
    <div className={classNames}>
      <label htmlFor={id}>
        {/* {label} */}
        {/* {required ? "*" : null} */}
      </label>
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
}

function ObjectFieldTemplate(props: any) {
  return (
    <div>
      {/* {props.title}
      {props.description} */}
      {props.properties.map((element: any) => (
        <div className="property-wrapper">{element.content}</div>
      ))}
    </div>
  );
}

function RangeTemplate(props: any) {
  return (
    <div className="grid grid-cols-4 mt-3">
      <div className="col-span-1">{props.value}</div>
      <input
        className="col-span-3"
        type="range"
        value={props.value || 10}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </div>
  );
}

function FieldErrorTemplate(props: any) {
  const { errors, id } = props;
  return (
    <div className="error mt-1" id={`${id}-error`}>
      {errors && !errors[0].includes('"oneOf"') ? (
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
          {errors[0]}
        </span>
      ) : null}
    </div>
  );
}

function BaseInput(props: any) {
  const { id, value, onChange, label } = props;
  return (
    <div className="mt-3">
      <label htmlFor={id} className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <input
        value={value ?? ""}
        onChange={(event) => onChange(event.target.value)}
        type="text"
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={label}
        required
      />
    </div>
  );
}

function Select(props: any) {
  const { id, value, onChange, label, options, required } = props;
  console.log("SELECT");
  console.log(props);
  return (
    <div className="mt-3">
      <label htmlFor={id} className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        id={id}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        required={required}
      >
        <option disabled={required ?? false} selected value={undefined}></option>
        {options?.enumOptions?.map((option: any) => <option value={option.value}>{option.label}</option>)}
      </select>
    </div>
  );
}

export const SubmitButton = (props: any) => {
  const { type, disabled } = props;
  return (
    <div className="grid">
      <button
        type={type}
        className="px-3 py-2 mt-3 justify-self-center text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        disabled={disabled}
      >
        Guardar
      </button>
    </div>
  );
};

export const ThemeObject: FormProps["templates"] = {
  ArrayFieldTemplate: MyArrayFieldTemplate,
  ErrorListTemplate: MyErrorListTemplate,
  FieldTemplate: CustomFieldTemplate,
  ObjectFieldTemplate,
  BaseInputTemplate: BaseInput,
  FieldErrorTemplate,
  ButtonTemplates: {
    SubmitButton,
  },
};

export const WidgetsObject: FormProps["widgets"] = {
  range: RangeTemplate,
  select: Select,
};

export default ThemeObject;
