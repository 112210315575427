import React, { Fragment, useContext } from "react";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import axios from "axios";
import localize_es from "ajv-i18n/localize/es";
import { Dialog, Transition } from "@headlessui/react";
import { log } from "../utils/logger";
import ThemeObject, { WidgetsObject } from "./rsjfTheme";

function transformErrors(errors: any) {
  const i18n_normalized = errors.map((e: any) => ({ ...e, keyword: e.name }));
  localize_es(i18n_normalized);
  console.log(i18n_normalized);
  return i18n_normalized;
}

export type FormContext = {
  open: boolean;
  editMode: boolean;
  data: any;
  schema: any;
  setOpen: (open: boolean) => void;
  setEditMode: (editMode: boolean) => void;
  setData: (formData: any) => void;
  setSchema: (schema: any) => void;
};

export type FormProps = {
  uiSchema?: any;
  context: React.Context<FormContext>;
  entity: string;
  onCreate?: () => void;
};

const JsonSchemaEntityForm = ({ uiSchema, context, entity, onCreate }: FormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { open, editMode, data, schema, setOpen, setData } = useContext(context);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const onSubmit = async (values: any) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/${entity}/${editMode ? "update" : "create"}`;
    await axios.post(url, values.formData);
    setOpen(false);
    if (onCreate !== undefined) {
      onCreate();
    }
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50">
                    {editMode ? "Editar entidad" : "Crear entidad"} {entity}
                  </Dialog.Title>
                  <Dialog.Description className="dark:text-gray-50">
                    Rellena los campos de la entidad
                  </Dialog.Description>

                  <Form
                    className="default-form"
                    schema={schema as any}
                    uiSchema={uiSchema}
                    transformErrors={transformErrors}
                    validator={validator}
                    liveValidate
                    formData={data}
                    templates={ThemeObject}
                    widgets={WidgetsObject}
                    // onChange={log("changed")}
                    onSubmit={onSubmit}
                    onError={log("errors")}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default JsonSchemaEntityForm;
