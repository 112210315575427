/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import { Tag, tagCreateRequest, tagUpdateRequest } from "@alexgue/wrapby-types-web";
import JsonSchemaEntityForm from "../components/JsonSchemaEntityForm";
import CustomTable, { CustomTableProps } from "../components/CustomTable";
import { deleteTag, listTags } from "../utils/client";
import { EntityDefinition } from "../types/EntityDefinition.type";

const uiSchema = {
  type: { code: { "ui:widget": "hidden" } },
  _id: { "ui:widget": "hidden" },
};
const entity = "tag";

const entityDefinition: EntityDefinition = {
  title: { text: "Título", type: "text" },
  slug: { text: "Slug", type: "text" },
  parentTag: { text: "Etiqueta Padre", type: "text" },
};

const defaultFormContext = {
  open: false,
  editMode: false,
  data: {},
  schema: {},
  setOpen: (open: boolean) => {},
  setEditMode: (editMode: boolean) => {},
  setData: (formData: Tag) => {},
  setSchema: (formSchema: typeof tagCreateRequest | typeof tagUpdateRequest) => {},
};

export const FormTagContext = createContext(defaultFormContext);

const TagPage: React.FC = () => {
  const defaultTableData: CustomTableProps = {
    entityDefinition,
    items: [],
  };
  const [tableData, setTableData] = useState(defaultTableData);
  const [formOpen, setFormOpen] = useState(false);
  const [formEditMode, setFormEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState({});

  const toggleCreateModal = async () => {
    const schemaPopulated = JSON.parse(JSON.stringify(tagCreateRequest));
    const tags = await listTags();
    schemaPopulated.properties.parentTag = {
      type: "string",
      enum: tags.map((tag) => tag.slug),
    };
    setFormData({});
    setFormSchema(schemaPopulated);
    setFormEditMode(false);
    setFormOpen(true);
  };

  const toggleEditModal = async (data: any) => {
    const updateData = {
      ...data,
    };
    delete updateData.createdAt;
    delete updateData.updatedAt;
    delete updateData.slug;
    const schemaPopulated = JSON.parse(JSON.stringify(tagUpdateRequest));
    const tags = await listTags();
    schemaPopulated.properties.parentTag = {
      type: "string",
      enum: tags.map((tag) => tag.slug),
    };
    setFormData({
      _id: data._id,
      title: data.title,
      parentTag: data.parentTag,
    });
    setFormSchema(schemaPopulated);
    setFormEditMode(true);
    setFormOpen(true);
  };

  async function loadData() {
    const items = await listTags();
    const props: CustomTableProps = {
      entityDefinition,
      items: items.map((item) => {
        return {
          data: item,
          functions: {
            edit: toggleEditModal,
            delete: deleteTag,
          },
        };
      }),
      update: loadData,
    };
    await setTableData(props);
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <FormTagContext.Provider
      value={{
        open: formOpen,
        editMode: formEditMode,
        data: formData,
        schema: formSchema,
        setData: setFormData,
        setEditMode: setFormEditMode,
        setOpen: setFormOpen,
        setSchema: setFormSchema,
      }}
    >
      <JsonSchemaEntityForm uiSchema={uiSchema} context={FormTagContext} entity={entity} onCreate={() => loadData()} />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Etiquetas</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={toggleCreateModal}
            className="relative px-3 py-2 text-sm focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Crear etiqueta
          </button>
          <CustomTable tableData={tableData} />
        </div>
      </main>
    </FormTagContext.Provider>
  );
};

export default TagPage;
