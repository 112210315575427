import { Form, Gift, Question, Session, Tag } from "@alexgue/wrapby-types-web";
import axios from "axios";
import { config } from "../config/config";
import { ACCESS_TOKEN_HEADER, REFRESH_TOKEN_HEADER } from "./consts";

const axiosClient = axios.create({
  baseURL: config.backendUrl,
  headers: {
    [ACCESS_TOKEN_HEADER]: localStorage.getItem(ACCESS_TOKEN_HEADER),
  },
});

export async function refreshToken() {
  const response = await axiosClient.post(
    `/user/refresh-token`,
    {},
    {
      headers: {
        [REFRESH_TOKEN_HEADER]: localStorage.getItem(REFRESH_TOKEN_HEADER),
      },
    },
  );
  if (response && response.status === 200) {
    // Set token to local storage
    localStorage.setItem(ACCESS_TOKEN_HEADER, response.data.result.accessToken);
    localStorage.setItem(REFRESH_TOKEN_HEADER, response.data.result.refreshToken);
  }
}

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.data?.error?.message === "TokenExpiredError") {
      refreshToken()
        .then(() => {
          const originalRequest = error.config;
          originalRequest.headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(ACCESS_TOKEN_HEADER);
          return axiosClient(originalRequest);
        })
        .catch(() => {
          window.location.href = "/login";
        });
    }
    return Promise.reject(error);
  },
);

export async function listTags(): Promise<Tag[]> {
  const result = await axiosClient.post(`/tag/list`, {});
  return result.data.result;
}

export async function deleteTag(id: string) {
  const result = await axiosClient.post(`/tag/delete`, {
    _id: id,
  });
  return result.data;
}

export async function listGifts(): Promise<Gift[]> {
  const result = await axiosClient.post(`/gift/list`, {});
  return result.data.result;
}

export async function deleteGift(id: string) {
  await axiosClient.post(`/gift/delete`, { _id: id });
}

export async function listForms(): Promise<Form[]> {
  const result = await axiosClient.post(`/form/list`, {});
  return result.data.result;
}

export async function deleteForm(id: string) {
  await axiosClient.post(`/form/delete`, { _id: id });
}

export async function listSessions(): Promise<Session[]> {
  const result = await axiosClient.post(`/session/list`, {});
  return result.data.result;
}

export async function deleteSession(id: string) {
  await axiosClient.post(`/session/delete`, { _id: id });
}

export async function listQuestions(): Promise<Question[]> {
  const result = await axiosClient.post(`/question/list`, {});
  return result.data.result;
}

export async function deleteQuestion(id: string) {
  await axiosClient.post(`/question/delete`, { _id: id });
}

export const cacheForms: Form[] = [];

export async function getFormById(id: string): Promise<Form | undefined> {
  if (!cacheForms.find((form) => form._id === id)) {
    const result = await axios.post(`${config.backendUrl}/form/get-by-id`, {
      formId: id,
    });
    cacheForms.push(result.data.result);
  }
  return cacheForms.find((form) => form._id === id);
}

export async function getQuestionById(id: string): Promise<Form> {
  const result = await axios.post(`${config.backendUrl}/question/get-by-id`, {
    _id: id,
  });
  return result.data.result;
}
